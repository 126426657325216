import React, { Suspense, lazy } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

const Home = lazy(() => import('./view/home'))
const MeusBilhetes = lazy(() => import('./view/meus-bilhetes'))
const MeuBilhete = lazy(() => import('./view/meu-bilhete'))

const Routes = () => (
    <Router forceRefresh>
        <Suspense fallback={<div/>}>
            <Switch>
                <Route exact path="/meus-bilhetes" component={MeusBilhetes} />
                <Route exact path="/meu-bilhete" component={MeuBilhete} />
                <Route exact path="/" component={Home} />
            </Switch>
        </Suspense>
    </Router>
)

export default Routes;