import React from 'react'
import Routes from './Routes'
import AOS from 'aos'
import 'aos/dist/aos.css'

import 'bootstrap/dist/css/bootstrap.min.css'
import './style/global.css'

const App = () => {
	React.useEffect(() => AOS.init({
		duration: 1000,
		delay: 300
	}), [])
	
	return (<Routes />)
}

export default App;